import React, { Component } from 'react'

export class Technology extends Component {
    render() {
        const { loc } = this.props;

        return (
            <div className="container mx-auto px-2 lg:pt-24 lg:pb-32 ">
                <div className="flex flex-wrap text-center justify-center">
                    <div className="lg:w-6/12 px-2">
                        <h7 className="text-xl mb-1 font-semibold text-black">
                            {loc.webdevelopment.what_we_do}
                        </h7>
                        <h2 className="text-4xl font-semibold text-black-500">
                            {loc.mobileappdevelopment.sb_heading}
                        </h2>
                        <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                            {loc.mobileappdevelopment.sb_details}
                        </p>
                    </div>
                </div>
                <div className="flex flex-wrap mt-6 justify-center">
                    <div className="lg:w-2/12 mb-4 px-2 text-center">
                        <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                            <img
                                alt="..."
                                src={require("assets/img/big-query-logo-85x75.webp").default}
                                className="align-middle rounded-t-lg"
                            />                </div>

                    </div>
                    <div className="lg:w-2/12 mb-4 px-2 text-center">
                        <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                            <img
                                alt="..."
                                src={require("assets/img/firebase-logo-55x75.webp").default}
                                className="align-middle rounded-t-lg"
                            />                </div>

                    </div>
                    <div className="lg:w-2/12 mb-4 px-2 text-center">
                        <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                            <img
                                alt="..."
                                src={require("assets/img/flutter.webp").default}
                                className="align-middle rounded-t-lg"
                            />                </div>

                    </div>
                    <div className="lg:w-2/12 mb-4 px-2 text-center">
                        <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                            <img
                                alt="..."
                                src={require("assets/img/javascript-logo-75x75.webp").default}
                                className="align-middle rounded-t-lg"
                            />                </div>

                    </div>
                    <div className="lg:w-2/12 mb-4 px-2 text-center">
                        <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                            <img
                                alt="..."
                                src={require("assets/img/kotlin-logo-75x75.webp").default}
                                className="align-middle rounded-t-lg"
                            />                </div>

                    </div>
                    <div className="lg:w-2/12 mb-4 px-2 text-center">
                        <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                            <img
                                alt="..."
                                src={require("assets/img/react-native-logo-85x75.webp").default}
                                className="align-middle rounded-t-lg"
                            />                </div>

                    </div>
                </div>
                <div className="flex flex-wrap mt-6 justify-center">

                    <div className="lg:w-2/12 mb-4 px-2 text-center">
                        <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                            <img
                                alt="..."
                                src={require("assets/img/swift-logo-75x75.webp").default}
                                className="align-middle rounded-t-lg"
                            />                </div>

                    </div>
                    <div className="lg:w-2/12 mb-4 px-2 text-center">
                        <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                            <img
                                alt="..."
                                src={require("assets/img/nodejs-logo-123x75.webp").default}
                                className="align-middle rounded-t-lg"
                            />                </div>

                    </div>

                </div>
            </div>
        )

    }
}

export default Technology