import React from 'react'

export default function CompanyChoose(props) {
    const loc = props.loc;

    return (
        <section className=" mt-12 pb-20 relative block bg-white-100">

            <div className="container mx-auto overflow-hidden pb-2">
                <div className="flex flex-wrap items-center">
                    <div
                        className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto "
                        id="feature"
                    >
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                            <img
                                alt="..."
                                src={require("assets/img/2.png").default}
                                className="w-full align-middle rounded-t-lg"
                            />
                            <blockquote className="relative p-8 mb-4">
                                <svg
                                    preserveAspectRatio="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 583 95"
                                    className="absolute left-0 w-full block h-95-px -top-94-px"
                                >
                                    <polygon
                                        points="-30,95 583,95 583,65"
                                        className="text-red-500 fill-current"
                                        style={{ color: "rgb(255,255,255)" }}
                                    ></polygon>
                                </svg>
                                <h4 className="text-xl font-bold text-black">
                                    {loc.companychoose.sb_heading}
                                </h4>
                                <p className="text-md font-light mt-2 text-black">
                                    {loc.companychoose.sb_details}
                                </p>
                            </blockquote>
                        </div>
                    </div>
                    <div className="w-full md:w-6/12 ml-auto mr-auto">
                        <div className="flex flex-wrap">
                            <div className="w-full flex flex-row flex-wrap px-4">
                                <div className="relative md:w-6/12 flex flex-col mt-4">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white-500 p-3 text-center inline-flex items-center justify-center w-4-h-4 mb-5 shadow-lg rounded-full bg-white">
                                            <img
                                                alt="..."
                                                src={require("assets/img/cost.webp").default}
                                                className="w-full align-middle rounded-t-lg"
                                            />
                                        </div>
                                        <h6 className="text-xl mb-1 font-semibold text-black">
                                            {loc.companychoose.cost}
                                        </h6>
                                        <p className="mb-4 text-black">
                                            {loc.companychoose.cost_detail}
                                        </p>
                                    </div>
                                </div>
                                <div className="relative md:w-6/12 flex flex-col min-w-0 mt-4">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white-500 p-3 text-center inline-flex items-center justify-center w-4-h-4 mb-5 shadow-lg rounded-full bg-white">
                                            <img
                                                alt="..."
                                                src={require("assets/img/adherence.webp").default}
                                                className="w-full align-middle rounded-t-lg"
                                            />                                                </div>
                                        <h6 className="text-xl mb-1 font-semibold text-black">
                                            {loc.companychoose.adhernce}
                                        </h6>
                                        <p className="mb-4 text-black">
                                            {loc.companychoose.adhernce_detail}{" "}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-row flex-wrap px-4">
                                <div className="relative md:w-6/12 flex flex-col min-w-0 mt-4">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white-500 p-3 text-center inline-flex items-center justify-center w-4-h-4 mb-5 shadow-lg rounded-full bg-white">
                                            <img
                                                alt="..."
                                                src={require("assets/img/commitment.webp").default}
                                                className="w-full align-middle rounded-t-lg"
                                            />                                                </div>
                                        <h6 className="text-xl mb-1 font-semibold text-black">
                                            {loc.companychoose.commitment}{" "}
                                        </h6>
                                        <p className="mb-4 text-black">
                                            {loc.companychoose.commitment_detail}
                                        </p>
                                    </div>
                                </div>
                                <div className="relative md:w-6/12 flex flex-col min-w-0 mt-4">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white-500 p-3 text-center inline-flex items-center justify-center w-4-h-4 mb-5 shadow-lg rounded-full bg-white">
                                            <img
                                                alt="..."
                                                src={require("assets/img/understanding.webp").default}
                                                className="w-full align-middle rounded-t-lg"
                                            />
                                        </div>
                                        <h6 className="text-xl mb-1 font-semibold text-black">
                                            {loc.companychoose.understanding}
                                        </h6>
                                        <p className="mb-4 text-black">
                                            {loc.companychoose.understanding_detail}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )

}

