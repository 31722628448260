const arabic = {
  sw_language: "E",
  dir: "rtl",
  nav: {
    mobileappdevelopment: "Mobile App Development",
    webdevelopment: "Web Development",
    contact_us: "اتصل بنا",
  },
  pitch: {
    heading: "Bedside Terminal Solution",
    details:
      "اكتشف مزايا Bedside Terminal Solution - نظام بديهي يمكّن مؤسسات الرعاية الصحية من تقديم رعاية استثنائية للمرضى يتكامل مع نظام HIS الخاص بالمستشفيات ، مما يضمن مشاركة البيانات بسلاسة بأعلى مستوى من الأمان.",
  },
  expertise: {
    sb_heading: "Our Expertise",
    sb_details:
      'Creative ideas and strategic insights. Trend-setting design. Smooth development process.We have real experience creating "The Next Big Thing" for industry leaders. We specialize in: ',
    frontend: "Web Frontend",
    backend: "Backend Development",
    UX: "UX/UI",
    mobiledev:"Mobile App Development",
    infrastructure: "Infrastructure and CI/CD",
  },
  companychoose:{
    sb_heading:"See why top companies choose US",
    sb_details:"Why we are a trusted partner in building the best software solutions",
    cost:"Cost & Time savings",
    cost_detail:"Outsourcing software development to our team means cutting costs while reducing the workload on the client’s team. By embracing agile development and devops approaches, we can accelerate time to market.",
    adhernce:"Adherence to quality",
    adhernce_detail:"While planning and implementing top-notch solutions, we focus on potential risks and pitfalls in order to deliver the best possible quality.",
    commitment:"Commitment & flexibility",
    commitment_detail:"Completely committed to client’s success, we can scale quickly and efficiently to accommodate the size and complexity of your project.",
    understanding:"Understanding the business",
    understanding_detail:"We always want to thoroughly understand the business domainand industry, so we can efficiently bridge the gap between client’s goals and technology."
  },
  discuss: {
    sb_heading: "Ready to discuss your project?",
    sb_details: "For us, the success of your project is the only metric we truly care about. That’s why we offer doing a 1-2 week Proof Of Concept (POC), providing exemplary services starting with a thorough assessment of your requirements.",
    define_poc_heading:"Define Poc",
    define_poc_details:"We discuss and clarify requirements and define a Proof Of Concept.",
    execute_heading:"Execute",
    execute_details:"We start work on the POC and collaborate to deliver the project.",
    evaluate_heading:"Evaluate",
    evaluate_details:"If you like output, you pay us and we start a more longer term relationship."
  },
  technology:{
    sb_heading:"Transform your business with new technologies",
    sb_details:"Hecta solutions helps companies use technology to solve core business problems"
  },
  pitch_mobileappdevelopment: {
    sb_heading: "Building a Mobile App?",
    sb_details: "Hit ‘Start My Project’ and let’s begin our conversation to walk you through our idea analysis and top-tier development services"
  },
  mobileappdevelopment: {
    sb_heading: "We seamlessly take you from ideation to a finished product",
    sb_details: "Entrepreneurs and companies trust us to take their revolutionary ideas and turn them into powerful mobile apps. Our seasoned engineers and creative designers ensure the best practices to deliver amazing results."
  },
  howwedo:{
    heading:"HOW WE DO IT",
    sb_heading:"We take care of the intricate heavy lifting, so you don’t have to!",
    sb_detail:"Hecta Solutions is composed of professionals who quickly assess the situation and pick their arsenal. Mobile development has evolved tremendously and has brought with it numerous tools and techniques. Our experienced developers know what tools and techniques will allow your project to become an MVP in the earliest time. From ideation to product maintenance, we carry you every step of the way."
  },
  gurantee:{
    heading:"OUR GUARANTEE",
    sb_heading:"Flawless functioning & modern essentials",
    sb_detail:"Seemingly small details can greatly impact the quality of a Mobile App. We ensure our apps are battery optimised and support secure payment gateways & accurate geolocation features - among other modern essentials. We’ve learned to handle all these game-breaking issues and whatever we create works flawlessly."
  },
  pitch_webdevelopment: {
    sb_heading: "Web applications are essential to dominate the market",
    sb_details: "Desktop and laptop users are still present in large volumes. Why miss the chance to cater to a potentially colossal audience?"
    },
  webdevelopment: {
    sb_heading: "Develop your business goals into scalable, user-centered products",
    sb_details: "The essence of a good web app lies in its ease of use and goal-accomplishing abilities. We design your goals into a tangible experience that works. An Agile methodology makes sure any necessary changes can easily be incorporated."
   },
  webhowwedo:{
    heading:"HOW WE DO IT",
    sb_heading:"Develop your business goals into scalable, user-centered products",
    sb_detail:"Drawing the best solution in terms of architecture and choosing the right framework so there are no limitations in that area. Hence, ensuring peak performance even when additional features are added! Our apps are sustainable since we ensure quality through best coding practices and performance testing."
  },
  webgurantee:{
    heading:"OUR GUARANTEE",
    sb_heading:"Secure, Seamless & Optimised!",
    sb_detail:"Secure login and payment systems, responsive design, and compatibility with all the latest browsers across all devices - are just some of the things you will never have to worry about. Our team understands that these are always required and should be implemented based on the specific product."
  },
  footer: {
    our_office: "Our Office",
    office_loc: " Dice Launchpad ",
    office_loc_1:"1st Floor, Al-Rehman Chamber, 79 East AKM Fazl-ul-Haq Rd",
    office_loc_2:"Blue Area Islamabad",
    phone: "Phone",
    phone_number_1:"+92 3215858597",
    phone_number_2:"+92 3045044441"
  },
};
export default arabic;
