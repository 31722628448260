import React, { Component } from 'react'
import Gurantee from './Gurantee/Gurantee';
import HowWeDo from './HowWeDo/HowWeDo';
import Technology from './Technology/Technology';

export class mobileappdevelopment extends Component {
    render() {
        const { loc } = this.props;

        return (
            <> <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
                <div className="container mx-auto items-center flex flex-wrap">
                    <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
                        <div className="pt-90 sm:pt-0">
                            <h2 className="font-semibold text-4xl text-hecta-600">
                                {loc.pitch_mobileappdevelopment.sb_heading}
                            </h2>
                            <p className="mt-4 text-lg leading-relaxed text-hecta-500">
                                {loc.pitch_mobileappdevelopment.sb_details}{" "}
                            </p>
                        </div>
                    </div>
                </div>

                <img
                    className={`absolute top-0 b-auto ${document.getElementsByTagName("html")[0].getAttribute("dir") ===
                        "ltr"
                        ? "right-0"
                        : "left-0 transform-hero-img"
                        } pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-6/12 max-h-860px`}
                    src={require("assets/img/mobile.png").default}
                    alt="..."
                />
            </section>
                <section className='mt-12 pb-20 relative block bg-hecta-600'>
                    <Technology loc={loc} />
                </section>
                <section className='pt-12 pb-20 relative block'>
                    <HowWeDo loc={loc} />
                </section>
                <section className='pt-12 pb-20 relative block bg-hecta-600'>
                    <Gurantee loc={loc} />
                </section>
            </>

        )
    }
}

export default mobileappdevelopment