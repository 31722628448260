import React, { Component } from 'react'

export class Gurantee extends Component {
  render() {
    const { loc } = this.props;

    return (
        <section className=" mt-12 pb-20 relative block bg-white-100">
            <div className="container mx-auto overflow-hidden pb-2">
                <div className="flex flex-wrap items-center">
                   
                    <div className="w-full md:w-6/12 ml-auto mr-auto">
                        <div className="flex flex-wrap">
                            <div className="w-full flex flex-row flex-wrap px-4">
                                <div className="relative md:w-12/12 flex flex-col mt-4">
                                    <div className="px-4 py-5 flex-auto">
                                        <h7 className="text-xl mb-1 font-semibold text-black">
                                            {loc.gurantee.heading}
                                        </h7>
                                        <h6 className="text-xl mb-1 font-semibold text-black">
                                            {loc.gurantee.sb_heading}
                                        </h6>
                                        <p className="mb-4 text-black">
                                            {loc.gurantee.sb_detail}
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div
                        className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto "
                        id="feature"                        >
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                            <img
                                alt="..."
                                src={require("assets/img/mobileapp_2.webp").default}
                                className="w-full align-middle rounded-t-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
  }
}

export default Gurantee