import React from "react";

export default function Footer(props) {
  const loc = props.loc;
  return (
    <>
      <footer className="relative bg-hecta-800 pt-8 pb-6">
       
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
            <div className="text-red-500 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fa fa-map text-xl"></i>
                </div>
              <h4 className="text-3xl font-semibold text-white">
                {loc.our_office}
              </h4>
              <h5 className="text-lg mt-0 mb-2 text-white">
                {loc.office_loc}
              </h5>
              <h5 className="text-lg mt-0 mb-2 text-white">
                {loc.office_loc_1}
              </h5>
              <h5 className="text-lg mt-0 mb-2 text-white">
                {loc.office_loc_2}
              </h5>
             
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                </div>
                <div className="w-full lg:w-4/12 px-4">
                <div className="text-red-500 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fa fa-phone text-xl"></i>
                </div>
                <h4 className="text-3xl font-semibold text-white">
                {loc.phone}
              </h4>
              <h5 className="text-lg mt-0 mb-2 text-white">
                {loc.phone_number_2}
              </h5>  
              <h5 className="text-lg mt-0 mb-2 text-white">
                {loc.phone_number_1}
              </h5>             
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-white font-semibold py-1">
                Copyright © {new Date().getFullYear()} Hecta Solutions. All rights reserved. 
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
