import React, { Component } from 'react'

export class Technology extends Component {
    render() {
        const { loc } = this.props;

        return (
            <>

                <div className="container mx-auto px-2 lg:pt-24 lg:pb-32">
                    <div className="flex flex-wrap text-center justify-center">
                        <div className="lg:w-6/12 px-2">
                            <h2 className="text-4xl font-semibold text-black-500">
                                {loc.technology.sb_heading}
                            </h2>
                            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                                {loc.technology.sb_details}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-6 justify-center">
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/angular.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/ansible.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/css.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/docker.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/elastic.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/grails.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                    </div>
                    <div className="flex flex-wrap mt-6 justify-center">

                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/hat.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/html5.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/java.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/jenkins.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/kubernetes.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/mongodb.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                    </div>
                    <div className="flex flex-wrap mt-6 justify-center">

                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/mysql.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>
                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/p.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>
                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/protractor.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>
                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/rails.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>
                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/s.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>
                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/selenium.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap mt-6 justify-center">
<div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/tizen.png").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/sqllite.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>
                        </div>
                        <div className="lg:w-2/12 mb-4 px-2 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/vuejs.webp").default}
                                    className="align-middle rounded-t-lg"
                                />                </div>

                        </div>

                    </div>







                </div >






            </>
        )
    }
}

export default Technology