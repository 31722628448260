import english from "./english";
import arabic from "./arabic";
const languages = {
  english,
  arabic,
};
const languageData = (languageChange) => {
  let locale = localStorage.getItem("locale") || "english";
  if (languageChange) {
    locale === "english" ? (locale = "arabic") : (locale = "english");
    localStorage.setItem("locale", locale);
  }
  let sl = {};
  if (Object.keys(languages).includes(locale)) {
    sl = languages[locale];
  } else {
    sl = languages["english"];
  }
  document.getElementsByTagName("html")[0].setAttribute("dir", sl.dir);
  if (sl.dir === "ltr") {
    document.getElementsByTagName("body")[0].classList.add("eng-font");
    document.getElementsByTagName("body")[0].classList.remove("arb-font");
  } else {
    document.getElementsByTagName("body")[0].classList.add("arb-font");
    document.getElementsByTagName("body")[0].classList.remove("eng-font");
  }

  return sl;
};

export default languageData;
