import React, { Component } from 'react'

export class Discuss extends Component {
    render() {
        const { loc } = this.props;

        return (
            <section className=" mt-12 pb-20 relative block bg-hecta-600 ">
                <div className="container mx-auto px-4 lg:pt-24 lg:pb-32">
                    <div className="flex flex-wrap text-center justify-center">
                        <div className="w-full lg:w-6/12 px-4">
                            <h2 className="text-4xl font-semibold text-black-500">
                                {loc.discuss.sb_heading}
                            </h2>
                            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                                {loc.discuss.sb_details}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-12 justify-center">
                        <div className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-red-500 p-3 w-4-h-4 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/poc.webp").default}
                                    className="w-full align-middle rounded-t-lg"
                                />                </div>
                            <h5 className="text-lg mt-5 font-semibold text-blueGray-500">
                                {loc.discuss.define_poc_heading}
                            </h5>
                            <p className="mb-4 text-black">
                                {loc.discuss.define_poc_details}
                            </p>
                        </div>
                        <div className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-red-500 p-3 w-4-h-4 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/execute.webp").default}
                                    className="w-full align-middle rounded-t-lg"
                                />                </div>
                            <h5 className="text-xl mt-5 font-semibold text-blueGray-500">
                                {" "}
                                {loc.discuss.execute_heading}
                            </h5>
                            <p className="mb-4 text-black">
                                {loc.discuss.execute_details}
                            </p>
                        </div>
                        <div className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-red-500 p-3 w-4-h-4 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/evaluate.webp").default}
                                    className="w-full align-middle rounded-t-lg"
                                />                </div>
                            <h5 className="text-xl mt-5 font-semibold text-blueGray-500">
                                {" "}
                                {loc.discuss.evaluate_heading}
                            </h5>
                            <p className="mb-4 text-black">
                                {loc.discuss.evaluate_details}
                            </p>
                        </div>
                    </div>

                </div>
            </section>

        )
    }
}


export default Discuss