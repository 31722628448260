import React, { Component } from 'react'

export class expertise extends Component {
    render() {
        const { loc } = this.props;

        return (


            <section className=" mt-12 pb-20 relative block bg-hecta-600">

                <div className="container mx-auto px-4 lg:pt-24 lg:pb-2">
                    <div className="flex flex-wrap text-center justify-center">
                        <div className="w-full lg:w-6/12 px-4">
                            <h2 className="text-4xl font-semibold text-black-500">
                                {loc.expertise.sb_heading}
                            </h2>
                            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                                {loc.expertise.sb_details}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-12 justify-center">
                        <div className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/webfront.webp").default}
                                    className="w-full align-middle rounded-t-lg"
                                />                </div>
                            <h6 className="text-lg mt-5 font-semibold text-blueGray-500">
                                {loc.expertise.frontend}
                            </h6>
                        </div>
                        <div className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/beckend.webp").default}
                                    className="w-full align-middle rounded-t-lg"
                                />                </div>
                            <h5 className="text-xl mt-5 font-semibold text-blueGray-500">
                                {" "}
                                {loc.expertise.backend}
                            </h5>
                        </div>
                        <div className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/ux.webp").default}
                                    className="w-full align-middle rounded-t-lg"
                                />                </div>
                            <h5 className="text-xl mt-5 font-semibold text-blueGray-500">
                                {" "}
                                {loc.expertise.UX}
                            </h5>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-12 justify-center">
                        <div className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/mobiledev.webp").default}
                                    className="w-full align-middle rounded-t-lg"
                                />                </div>
                            <h6 className="text-lg mt-5 font-semibold text-blueGray-500">
                                {loc.expertise.mobiledev}
                            </h6>
                        </div>
                        <div className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/infra.webp").default}
                                    className="w-full align-middle rounded-t-lg"
                                />                </div>
                            <h5 className="text-xl mt-5 font-semibold text-blueGray-500">
                                {" "}
                                {loc.expertise.infrastructure}
                            </h5>
                        </div>
                        <div className="w-full lg:w-3/12 px-4 text-center">
                            <div className="text-red-500 p-3 w-6-h-6 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                <img
                                    alt="..."
                                    src={require("assets/img/blockchain.webp").default}
                                    className="w-full align-middle rounded-t-lg"
                                />                </div>
                            <h5 className="text-xl mt-5 font-semibold text-blueGray-500">
                                {" "}
                                {loc.expertise.blockchain}
                            </h5>
                        </div>

                    </div>
                </div>
            </section>

        )
    }
}

export default expertise